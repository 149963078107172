/*
  URL normalizer
*/
@function getURL($path) {
  @return url("../../" + $path);
}

/*
  Calculate font-size in rem by px
*/
@function getFontSizeRem($size) {
  @return ($size / 60) * 1rem;
}