@mixin end-room {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  font-family: "FuturaPT-Medium", sans-serif;

  .unlocker-msg {
    font-size: getFontSizeRem(46);

    span {
      font-family: "FuturaPT-Bold", sans-serif;
      &::first-letter {
        text-transform: uppercase;
      }
    }
    margin-bottom: 1.5rem;
  }

  h1 {
    @include play-fair-regular;
    font-size: getFontSizeRem(120);
    margin-bottom: 1.5rem;
    text-align: center;
    text-transform: uppercase;
  }

  .here-is {
    font-size: getFontSizeRem(36);
    margin-bottom: 0.5rem;
    text-align: center;
    line-height: 0.85rem;

    span {
      font-family: "FuturaPT-Bold", sans-serif;
    }
  }

  .room-progress-theme-end-room {
    width: 27%;
    max-width: 550px;

    ul {
      width: 100%;
      position: relative;
      z-index: 1;

      li {
        border: 1px solid $blue-deep;
        font-size: getFontSizeRem(18);
        width: 0.75rem;
        height: 0.75rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $nude;
      }

      .room-resolved {
        position: relative;

        span {
          color: $blue-deep;
          opacity: 0;
        }

        &::before {
          content: "";
          position: absolute;
          background-image: getURL('assets/images/check.svg');
          background-repeat: no-repeat;
          background-position: center;
          background-size: 100%;
          width: 50%;
          height: 50%;
        }
      }

      &:after {
        content: "";
        position: absolute;
        height: 1px;
        width: 100%;
        background-color: $blue-deep;
        top: 50%;
        z-index: -1;
      }

      .is-next {
        animation: pulse 1s infinite;
      }

      @keyframes pulse {
        0% {
          transform: scale(1);
        }
        50% {
          transform: scale(1.2);
        }
        100% {
          transform: scale(1);
        }
      }
    }
  }

  .anim-wrapper {
    width: 5vw;
    height: 5vw;
    overflow: hidden;
    position: relative;

    img {
      width: 120%;
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}