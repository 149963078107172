@mixin pre-game {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;

  .logo-container {
    margin-bottom: 0.75rem;
  }

  h1 {
    @include play-fair-regular;
    font-size: getFontSizeRem(90);
    margin-bottom: 1.5rem;
  }

  .intro {
    font-family: "FuturaPT-Book", sans-serif;
    font-size: getFontSizeRem(22);
    line-height: 0.5rem;
    text-align: center;
    width: 40%;

    p {
      margin-bottom: 0.5rem;
    }
    margin-bottom: 0.25rem;
  }

  .explanation {
    width: 75%;
    flex: 1;

    .cols {
      display: flex;
    }

    .together {
      font-size: getFontSizeRem(30);
      margin: 0.35rem 0;
      text-align: center;
    }

    .col {
      flex: 1;

      &:last-of-type {
        margin-left: 0.5rem;
      }
    }

    h2 {
      font-family: "FuturaPT-Bold", sans-serif;
      font-size: getFontSizeRem(22);
    }

    p {
      font-family: "FuturaPT-Book", sans-serif;
      font-size: getFontSizeRem(22);
      line-height: 0.5rem;
      margin-bottom: 0.5rem;
    }
  }

  .how-to-message {
    font-size: getFontSizeRem(35);
    padding: 0.5rem 0;
    width: 100%;
    display: flex;

    .come-back-later {
      width: 100%;
      text-align: center;
    }

    .label {
      flex-basis: 53%;
      text-align: right;
    }

    .timer {
      flex: 1;
      margin-left: 0.2rem;

      .countdown {
        width: 1.5rem;
        display: inline-block;
      }
    }

    .time-unit {
      font-family: "FuturaPT-Book", sans-serif;
      font-size: getFontSizeRem(22);
    }
  }
}