@mixin hall-of-fame {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background-color: $blue-deep;
  background-image: getURL('assets/images/white-building-wireframe.svg');
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;

  .hof-inner {
    display: flex;
    flex-direction: column;
    color: $orange;
    height: 100%;
    align-items: center;

    .title-wrapper {
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;

      h1 {
        @include play-fair-regular;
        margin-top: 1rem;
        font-size: getFontSizeRem(90);
      }
    }

    .rank-header {
      width: 100%;
      margin-top: 1rem;

      table {
        width: 50%;
        margin: 0 auto;

        border-bottom: 1px solid $orange;

        th {
          flex-basis: 20%;
          font-size: getFontSizeRem(16);
          text-align: left;
          padding-bottom: 0.25rem;
        }
      }
    }

    .rank, .grank {
      width: 15%;
    }

    .name {
      width: 40%;
      text-transform: uppercase;
    }

    .rank-wrapper {
      flex: 1;
      width: 100%;
      overflow-y: auto;
      margin-bottom: 1rem;

      table {
        width: 50%;
        margin: 0 auto;

        tr {
          border-bottom: 1px solid $orange;
        }

        tr.loser-line {
          opacity: 0.5;
        }

        tr.the-team {
          color: $white;
          background-color: rgba(255, 255, 255, 0.05);
        }

        td {
          font-size: getFontSizeRem(22);
          padding: 0.2rem 0 0.1rem;
        }
      }
    }
  }
}