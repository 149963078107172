@mixin team-selection {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;

  background-image: getURL('assets/images/white-building-wireframe.svg');
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;

  .welcome-wrapper {
    flex: 1;
  }

  h1 {
    font-size: getFontSizeRem(200);
    @include play-fair-regular;
    margin-left: 2rem;
    margin-bottom: 0.5rem;
  }

  .teamcode-form {
    display: flex;
    margin-left: 3rem;

    .field-label {
      font-size: getFontSizeRem(46);
      white-space: nowrap;
      margin: 0.1rem 0.5rem 0;
    }
  }
}