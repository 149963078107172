@mixin quiz {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  font-size: getFontSizeRem(22);

  .logo-container {
    flex-basis: unset;
    margin-bottom: 0.5rem;
  }

  .quiz-content-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;

    .intro {
      font-family: "FuturaPT-Book", sans-serif;
      width: 35%;
      text-align: center;
      line-height: getFontSizeRem(30);
      margin-bottom: 0.75rem;
    }

    .quiz-wrapper {
      width: 85%;

      .quiz-item-wrapper {
        margin-bottom: 0.5rem;

        .responses-wrapper {
          display: flex;

          .col {
            flex: 1;
          }
        }

        .linear {
          flex-wrap: wrap;
        }

        h1 {
          font-family: "FuturaPT-Bold", sans-serif;
          font-size: getFontSizeRem(22);
          line-height: 0.5rem;
          text-transform: uppercase;
          margin-bottom: 0.35rem;
        }

        .title-underline {
          width: 21%;
          margin-bottom: 0.4rem
        }

        .btn-response-item {
          background: transparent;
          border: none;
          display: flex;
          align-items: flex-start;
          font-family: "FuturaPT-Book", sans-serif;
          margin-right: 0.5rem;
          margin-bottom: 0.25rem;

          span {
            display: inline-block;
            flex: 1;
            text-align: left;
          }

          img {
            margin-top: 0.12rem;
            margin-right: 13px;
          }
        }
      }
    }
  }

  .error-message {
    font-family: "FuturaPT-Medium", sans-serif;
    font-size: getFontSizeRem(16);
    margin: 0.5rem 0;
    text-transform: uppercase;
    color: $red;
  }
}
