@mixin help {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;

  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: $nude;

  font-family: "FuturaPT-Book", sans-serif;
  font-size: getFontSizeRem(22);

  z-index: 2;

  h1 {
    font-family: 'Playfair-Display-Regular', serif;
    font-size: getFontSizeRem(90);
    margin: 1.5rem 0 1rem
  }

  section.content {
    flex: 1;
    width: 100%;
    overflow-y: auto;
    transition: opacity 0.25s linear;

    .inner {
      margin: 0 auto;
      width: 60%;
    }

    .question-item {
      position: relative;

      button {
        display: block;
        width: 100%;
        text-align: left;
        position: relative;
        padding: 0.25rem 0;
        background: transparent;
        border: none;
        border-top: solid 1px $blue-deep;

        span.question {
          text-transform: uppercase;
        }

        span.icon {
          display: inline-block;
          width: 0.5rem;
          height: 0.5rem;
          background-image: getURL('assets/images/icon-keypad-up-on.svg');
          background-repeat: no-repeat;
          background-position: center 46%;
          background-size: 100% auto;
          position: absolute;
          right: 0.25rem;
          transition: transform 0.25s ease-out;
        }
      }

      &:last-child {
        border-bottom: solid 1px $blue-deep;
      }

      p.response-text {
        line-height: 0.45rem;
        overflow: hidden;
        transition: all 0.25s ease-out;
      }

      p.response-text-phantom {
        line-height: 0.45rem;
        position: absolute;
        visibility: hidden;
        opacity: 0;
      }
    }

    .selected {
      span.icon {
        transform: rotate(180deg);
      }

      p.response-text {
        line-height: 0.45rem;
        margin-bottom: 0.25rem;
        overflow: hidden;
      }

      p.response-text-phantom {
        margin-bottom: 0.25rem;
      }
    }
  }

  .btn-back-container {
    width: 100%;
    padding: 0.5rem 0 0.5rem 2rem;

    button {
      background: transparent;
      border: none;
      font-family: "FuturaPT-Medium", sans-serif;
      font-size: getFontSizeRem(35);
    }
  }

}
