html {
  width: 100%;
  height: 100%;

  font-size: calc(30px + (60 - 30) * ((100vw - 1200px) / (1920 - 1200)));
  line-height: calc(1.3em + (1.5 - 1.2) * ((100vw - 1200px) / (1920 - 1200)));

  @media screen and (max-width: 1200px) {
    font-size: 30px;
  }
  @media screen and (min-width: 1920px) {
    font-size: 60px;
  }
}

*:not(input) {
  user-select: none;
}

body {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: $nude;

  font-family: "FuturaPT-Medium", sans-serif;
  color: $blue-deep;

  .seamless-button {
    border: none;
    background-color: transparent;
  }
}

.date-server {
  display: none;
  font-size: 18px;
  position: absolute;
  z-index: 1000000000;
  background-color: $white;
}

#root {
  width: 100%;
  height: 100%;

  .app-wrapper {
    width: 100%;
    height: 100%;

    /* Spinner */
    .loader-container {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: adjust_color($white, $alpha: -0.7);
      z-index: 1000;
    }

    /* Input */
    .input-form-wrapper {

      .input-wrapper {
        display: flex;
        font-size: getFontSizeRem(46);

        input {
          width: 300px;
          font-family: "FuturaPT-Medium", sans-serif;
          font-size: getFontSizeRem(36);
          padding: 0.05rem;
        }

        button {
          color: $white;
          font-size: 0.35rem;
          background-color: $black;
          padding: 0 0.25rem;
        }
      }

      p.message {
        margin-top: 0.125rem;
        font-family: "FuturaPT-Book", sans-serif;
        font-size: getFontSizeRem(16);
        color: $red;
      }
    }

    /* Button sign out */
    .btn-sign-out-wrapper {
      width: 0.75rem;
      height: 0.75rem;
      position: absolute;
      bottom: 0.75rem;
      right: 0.75rem;

      button {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: 1px solid $orange;
        font-family: "FuturaPT-Medium", sans-serif;
        font-size: getFontSizeRem(18);
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        position: relative;;

        &::after {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          background-image: getURL('assets/images/sign-out-light.svg');
          background-repeat: no-repeat;
          background-position: center;
          background-size: 60%;
          transform: translateY(-5%);
        }
      }

      .dark {
        border: 1px solid $blue-deep;

        &::after {
          background-image: getURL('assets/images/sign-out-dark.svg');
        }
      }
    }

    /* Room progress */
    .room-progress {
      ul {
        display: flex;
        justify-content: space-between;

        li {
          border-radius: 50%;
        }
      }
    }

    /* Logo top */
    .logo-container {
      flex-basis: 20%;
      text-align: center;
      margin-bottom: 1rem;

      img {
        width: auto;
        height: 100%;
        max-height: 120px;
      }
    }

    /* Some underline */
    .title-underline {
      background-color: $blue-deep;
      height: 1px;
      width: 40%;
      margin: 0.2rem 0;
    }

    /* Main modal */
    .modal-wrapper {
      @include modal-wrapper;
    }

    .modal-open {
      opacity: 1;
      visibility: visible;
      transition: .4s all linear;

      .modal-inner {
        transition: .4s all .2s cubic-bezier(.45,.26,.47,1);
        transform: translate3d(0, 0, 0);
        opacity: 1;
        visibility: visible;
      }
    }

    /* Num pad */
    .keypad-btn {
      @include futura-pt-book-light;
      font-size: 1.8vw;
      color: $orange;
      border: solid 1px $orange;
      border-radius: 50%;
      background-color: transparent;
      width: percentage(.175);
      margin: 5%;
      padding: 0;

      @media (min-width: 1980px) {
        font-size: 40px;
      }

      div {
        position: relative;
        padding: 100% 0 0;

        span,
        &:before {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          display: flex;
          justify-content: center;
          align-items: center;
        }

      }

      &:hover, &:active {
        color: $blue-deep;
        background-color: $orange;
      }

      &.hidden {
        visibility: hidden;
      }
    }

    .up, .down, .left, .right {
      div {
        span {
          display: none;
        }

        &:before {
          content: "";
          background-image: getURL('assets/images/icon-keypad-up-off.svg');
          background-repeat: no-repeat;
          background-position: center 46%;
          background-size: 47% auto;
        }
      }

      &:hover, &:active {
        div:before {
          background-image: getURL('assets/images/icon-keypad-up-on.svg');
        }
      }
    }

    .left {
      div {
        &:before {
          transform: rotate(-90deg);
        }
      }
    }

    .right {
      div {
        &:before {
          transform: rotate(90deg);
        }
      }
    }

    .down {
      div {
        &:before {
          transform: rotate(180deg);
        }
      }
    }

    .suppr {
      div {
        span {
          display: none;
        }

        &:before {
          content: "";
          background-image: getURL('assets/images/icon-clear-off.svg');
          background-repeat: no-repeat;
          background-position: center;
          background-size: 50% auto;
        }
      }

      &:hover, &:active {
        div:before {
          background-image: getURL('assets/images/icon-clear-on.svg');
        }
      }
    }

    .validate {
      div {
        span {
          display: none;
        }

        &:before {
          content: "";
          background-image: getURL('assets/images/icon-validate-off.svg');
          background-repeat: no-repeat;
          background-position: center;
          background-size: 40% auto;
        }
      }

      &:hover, &:active {
        div:before {
          background-image: getURL('assets/images/icon-validate-on.svg');
        }
      }
    }

    .numpad-container {
      width: 40%;
      min-width: 400px;
    }

    .numpad-inner {
      width: 100%;
      color: $orange;
      text-align: center;

      h1 {
        @include futura-pt-book-light;
        font-size: getFontSizeRem(60);
        text-transform: uppercase;
        margin: 0.25rem 0;
      }

      .input-state {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 0.5rem;

        .digits-container {
          display: flex;
          justify-content: center;

          .code-state-indicator {
            width: 20px;
            height: 20px;
            border: solid 1px $orange;
            border-radius: 50%;
            margin: 0 0.125rem 0.25rem;
          }
        }

        .message {
          @include futura-pt-book-light;
          font-size: getFontSizeRem(22);
          text-align: center;
          visibility: hidden;
        }
      }

      .input-state-wrong {

        .code-state-indicator {
          background-color: $red;
          border: solid 1px $red !important;
        }

        .message {
          color: $red;
          visibility: visible;
        }
      }

      .input-state-correct, .input-state-inputmode {

        .code-state-indicator {
          background-color: $orange;
          border: solid 1px $orange;
        }

        .message {
          color: $orange;
          visibility: visible;
        }
      }



      section.numpad-keys-wrapper {
        .padline {
          display: flex;
          justify-content: center;
        }
      }


    }

    /* Toast */
    .toast-container {
      position: fixed;
      bottom: 0;
      width: 100%;
      padding: 0.5rem;
      background-color: $blue-deep;
      animation: pulseOpacity 5s infinite;
      pointer-events: none;

      p {
        color: $orange;
        font-size: getFontSizeRem(30);
        text-align: center;
      }
    }

    @keyframes pulseOpacity {
      0% {
        opacity: 0.25;
      }
      50% {
        opacity: 0.75;
      }
      100% {
        opacity: 0.25;
      }
    }

    /* Team selection */
    .team-selection-wrapper {
      @include team-selection;
    }

    /* Username definition */
    .username-definition-wrapper {
      @include username-definition;
    }

    /* Quiz */
    .quiz-container {
      @include quiz;
    }

    /* Pre-game */
    .pre-game-wrapper {
      @include pre-game;
    }

    /* The game */
    .the-game-wrapper {
      @include the-game;
    }

    /* End room */
    .end-room-wrapper {
      @include end-room;
    }

    /* Hall Of fame */
    .hall-of-fame-container {
      @include hall-of-fame;
    }

    /* Help */
    .help-container {
      @include help;
    }
  }
}
