@mixin the-game {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  display: flex;

  .room-container {
    flex: 1 1 0;
    height: 100%;
    overflow: auto;

    .room-game-container {
      width: 100%;
      height: 100%;
      position: relative;
    }
  }

  .room-root-level {
    width: percentage(1);
    height: percentage(1);
    background: $blue-deep;
    position: relative;
    overflow: hidden;
  }

  .room-wrapper {
    width: percentage(1);
    height: percentage(1);
    position: absolute;
    top: percentage(.5);
    left: percentage(.5);
  }

  .room-level {
    width: percentage(1);
    height: percentage(1);
    position: absolute;
    opacity: 0;
    visibility: hidden;
    background: getURL('assets/images/lvmh-ethics-compliance.png') center center no-repeat;
    background-size: 220px auto;
    backface-visibility: hidden;

    img {
      width: percentage(1);
      height: auto;
      opacity: 0;
      visibility: hidden;
      transition: .25s all ease-in;
      pointer-events: none;
    }

    .areas {
      width: percentage(1);
      height: percentage(1);
      position: absolute;
      top: 0;
      left: 0;

      div {
        position: absolute;
      }
    }

    .numpad {
      background: $blue-deep;
      width: percentage(1);
      height: percentage(1);
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.ready {
      img {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .numpad {
    background: $blue-deep;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
  }

  .room-1 {
    .room-level-1 {
      .areas {
        div {
          &:nth-child(1) {
            top: percentage(.4);
            left: 0;
            width: percentage(.17);
            padding: percentage(.33) 0 0;
          }

          &:nth-child(2) {
            top: percentage(.46);
            left: percentage(.2);
            width: percentage(.22);
            padding: percentage(.25) 0 0;
          }

          &:nth-child(3) {
            top: percentage(.5);
            left: percentage(.46);
            width: percentage(.22);
            padding: percentage(.19) 0 0;
          }

          &:nth-child(4) {
            top: percentage(.67);
            left: percentage(.69);
            width: percentage(.32);
            padding: percentage(.25) 0 0;
          }

          &:nth-child(5) {
            top: percentage(.2);
            left: percentage(.63);
            width: percentage(.2);
            padding: percentage(.22) 0 0;
          }
        }
      }
    }

    .room-level-2 {
      &-1 {
        .areas {
          div {
            &:nth-child(1) {
              top: percentage(.2);
              left: percentage(.15);
              width: percentage(.35);
              padding: percentage(.5) 0 0;
            }
          }
        }
      }

      &-2 {
        .areas {
          div {
            &:nth-child(1) {
              top: percentage(.3);
              left: percentage(0);
              width: percentage(.2);
              padding: percentage(.25) 0 0;
            }

            &:nth-child(2) {
              top: percentage(0);
              left: percentage(.23);
              width: percentage(.2);
              padding: percentage(.63) 0 0;
            }

            &:nth-child(3) {
              top: percentage(.36);
              left: percentage(.46);
              width: percentage(.13);
              padding: percentage(.23) 0 0;
            }
          }
        }
      }

      &-3 {
        .areas {
          div {
            &:nth-child(1) {
              top: percentage(.38);
              left: percentage(0);
              width: percentage(.68);
              padding: percentage(.5) 0 0;
            }

            &:nth-child(2) {
              top: percentage(0);
              left: percentage(.7);
              width: percentage(.3);
              padding: percentage(.33) 0 0;
            }
          }
        }
      }

      &-4 {
        .areas {
          div {
            &:nth-child(1) {
              top: percentage(.24);
              left: percentage(.18);
              width: percentage(.46);
              padding: percentage(.2) 0 0;
            }

            &:nth-child(2) {
              top: percentage(.24);
              left: percentage(.66);
              width: percentage(.16);
              padding: percentage(.2) 0 0;
            }

            &:nth-child(3) {
              top: percentage(.24);
              left: percentage(.86);
              width: percentage(.14);
              padding: percentage(.2) 0 0;
            }
          }
        }
      }

      &-5 {
        .areas {
          div {
            &:nth-child(1) {
              top: percentage(.57);
              left: percentage(.14);
              width: percentage(.24);
              padding: percentage(.2) 0 0;
            }
          }
        }
      }
    }
  }

  .room-2 {
    .room-level-1 {
      .areas {
        div {
          &:nth-child(1) {
            top: percentage(.34);
            left: 0;
            width: percentage(.21);
            padding: percentage(.25) 0 0;
          }

          &:nth-child(2) {
            top: percentage(.46);
            left: percentage(.25);
            width: percentage(.056);
            padding: percentage(.06) 0 0;
          }

          &:nth-child(3) {
            top: percentage(.544);
            left: percentage(.25);
            width: percentage(.056);
            padding: percentage(.046) 0 0;
          }

          &:nth-child(4) {
            top: percentage(.45);
            left: percentage(.324);
            width: percentage(.11);
            padding: percentage(.13) 0 0;
          }

          &:nth-child(5) {
            top: percentage(.49);
            left: percentage(.44);
            width: percentage(.034);
            padding: percentage(.07) 0 0;
          }

          &:nth-child(6) {
            top: percentage(.59);
            left: percentage(.45);
            width: percentage(.023);
            padding: percentage(.024) 0 0;
          }

          &:nth-child(7) {
            top: percentage(.41);
            left: percentage(.53);
            width: percentage(.26);
            padding: percentage(.18) 0 0;
          }

          &:nth-child(8) {
            top: percentage(.44);
            left: percentage(.81);
            width: percentage(.12);
            padding: percentage(.07) 0 0;
            transform: skewY(-5deg);
          }

          &:nth-child(9) {
            top: percentage(.535);
            left: percentage(.85);
            width: percentage(.074);
            padding: percentage(.06) 0 0;
            transform: skewY(-2deg);
          }

          &:nth-child(10) {
            top: percentage(.53);
            left: percentage(.926);
            width: percentage(.057);
            padding: percentage(.06) 0 0;
            transform: skewY(-2deg);
          }
        }
      }
    }

    .room-level-2 {
      &-2 {
        .areas {
          div {
            &:nth-child(1) {
              top: percentage(.8);
              left: percentage(.86);
              width: percentage(.11);
              padding: percentage(.06) 0 0;
            }
          }
        }
      }

      &-3 {
        .areas {
          div {
            &:nth-child(1) {
              top: percentage(.71);
              left: percentage(.86);
              width: percentage(.14);
              padding: percentage(.08) 0 0;
            }
          }
        }
      }

      &-4 {
        .areas {
          div {
            &:nth-child(1) {
              top: percentage(.69);
              left: percentage(.79);
              width: percentage(.14);
              padding: percentage(.08) 0 0;
            }
          }
        }
      }

      &-5 {
        .areas {
          div {
            &:nth-child(1) {
              top: percentage(.18);
              left: percentage(.7);
              width: percentage(.17);
              padding: percentage(.14) 0 0;
            }
          }
        }
      }

      &-6 {
        .areas {
          div {
            &:nth-child(1) {
              top: percentage(.71);
              left: percentage(.79);
              width: percentage(.14);
              padding: percentage(.08) 0 0;
            }
          }
        }
      }

      &-7 {
        .areas {
          div {
            &:nth-child(1) {
              top: percentage(.6);
              left: percentage(.91);
              width: percentage(.09);
              padding: percentage(.08) 0 0;
            }

            &:nth-child(2) {
              top: percentage(.186);
              left: percentage(.836);
              width: percentage(.019);
              padding: percentage(.018) 0 0;
              border-radius: percentage(.5);
            }
          }
        }
      }

      &-8 {
        .areas {
          div {
            &:nth-child(1) {
              top: percentage(.58);
              left: percentage(.94);
              width: percentage(.06);
              padding: percentage(.08) 0 0;
            }
          }
        }
      }

      &-9 {
        .areas {
          div {
            &:nth-child(1) {
              top: percentage(.47);
              left: percentage(0);
              width: percentage(.14);
              padding: percentage(.1) 0 0;
            }
          }
        }
      }

      &-10 {
        .areas {
          div {
            &:nth-child(1) {
              top: percentage(.7);
              left: percentage(.82);
              width: percentage(.18);
              padding: percentage(.08) 0 0;
            }
          }
        }
      }
    }
  }

  .room-3 {
    .room-level-1 {
      .areas {
        div {
          &:nth-child(1) {
            top: percentage(.81);
            left: percentage(.02);
            width: percentage(.1);
            padding: percentage(.08) 0 0;
          }

          &:nth-child(2) {
            top: percentage(.34);
            left: percentage(.75);
            width: percentage(.1);
            padding: percentage(.06) 0 0;
          }

          &:nth-child(3) {
            top: percentage(.29);
            left: percentage(.88);
            width: percentage(.12);
            padding: percentage(.18) 0 0;
          }

          &:nth-child(4) {
            top: percentage(.7);
            left: percentage(.38);
            width: percentage(.07);
            padding: percentage(.06) 0 0;
          }

          &:nth-child(5) {
            top: percentage(.74);
            left: percentage(.75);
            width: percentage(.1);
            padding: percentage(.06) 0 0;
          }

          &:nth-child(6) {
            top: percentage(.12);
            left: percentage(.88);
            width: percentage(.12);
            padding: percentage(.08) 0 0;
          }

          &:nth-child(7) {
            top: percentage(.43);
            left: percentage(.485);
            width: percentage(.07);
            padding: percentage(.06) 0 0;
          }

          &:nth-child(8) {
            top: percentage(.24);
            left: percentage(.36);
            width: percentage(.09);
            padding: percentage(.07) 0 0;
          }

          &:nth-child(9) {
            top: percentage(.75);
            left: percentage(.456);
            width: percentage(.09);
            padding: percentage(.084) 0 0;
          }

          &:nth-child(10) {
            top: percentage(.5);
            left: percentage(.7);
            width: percentage(.09);
            padding: percentage(.05) 0 0;
          }

          &:nth-child(11) {
            top: percentage(.56);
            left: percentage(.57);
            width: percentage(.06);
            padding: percentage(.044) 0 0;
          }

          &:nth-child(12) {
            top: percentage(.58);
            left: percentage(.01);
            width: percentage(.08);
            padding: percentage(.10) 0 0;
          }
        }
      }
    }
  }

  .room-form {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba($blue-deep, .98);
    color: $orange;
    display: flex;
    align-items: center;
    padding: 50px 40px;

    > div {
      flex-grow: 0;
      flex-shrink: 0;
    }

    .label-wrapper {
      @include play-fair-regular;
      font-size: .7rem;
      line-height: 1.4;
      padding: 0 30px 0 0;
      flex: 1 1 0;

      @media (min-width: 1980px) {
        font-size: 34px;
      }
    }

    .input-form-wrapper {
      input {
        width: calc(100% - 32px);
        border: none;
        line-height: 32px;
      }

      button {
        display: inline-block;
        background: $orange;
        border: none;
        color: black;
        padding: 0 15px;
        font-size: 14px;
      }
      // width: percentage(1/4);

      .message {
        position: absolute;
      }
    }

    .bonus-wrapper {
      padding: 0 0 0 30px;
      width: 150px;

      p {
        font-family: 'FuturaPT-Medium';
        text-transform: uppercase;
        font-size: 17px;
      }

      .bonus-points {
        font-size: 30px;

        span {
          font-family: 'FuturaPT-Light';
          font-size: 17px;
        }
      }
    }
  }

  .close {
    position: absolute;
    top: 20px;
    left: 20px;
    width: percentage(.08);
    background: $blue-deep;
    color: white;
    z-index: 2;
    border: 2px solid $nude;
    text-transform: uppercase;
    border-radius: percentage(.5);
    font-family: "FuturaPT-Book", sans-serif;
    font-size: .5rem;
    transition: .4s all;
    opacity: 0;
    visibility: hidden;
    margin: 0;
    padding: 0;

    div {
      padding: percentage(1) 0 0;
      position: relative;
    }

    span {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:hover {
      background: $nude;
      color: $blue-deep;
    }

    &.active {
      opacity: 1;
      visibility: visible;
    }

    .loading &,
    .modal-open & {
      opacity: 0 !important;
      visibility: hidden !important;
    }
  }

  .game-hud-wrapper {
    color: $orange;
    background: $blue-deep;
    padding: 20px;
    overflow-y: auto;
    flex-grow: 0;
    flex-shrink: 0;
    width: 24.94%;
    max-width: 479px;

    .blocks-wrapper {
      padding-top: 0.5rem;
    }

    .room-progress-theme-hud {
      ul {
        width: 100%;
        position: relative;
        z-index: 1;

        li {
          border: 1px solid $orange;
          font-size: getFontSizeRem(16);
          width: 0.5rem;
          height: 0.5rem;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: $blue-deep;
        }

        .room-opened, .room-resolved {
          background-color: $orange;

          span {
            color: $blue-deep;
          }
        }

        &:after {
          content: "";
          position: absolute;
          height: 1px;
          width: 100%;
          background-color: $orange;
          top: 50%;
          z-index: -1;
        }
      }
    }

    .block {
      margin-bottom: 0.54rem;

      .title {
        padding: 0 0 0.25rem 0;
        border-bottom: solid 1px adjust_color($orange, $alpha: -0.5);;
        margin-bottom: 0.35rem;
        display: flex;
        justify-content: space-between;

        h1 {
          font-size: getFontSizeRem(22);
          font-weight: 800;
          text-transform: uppercase;
        }
      }
    }

    .help-sign-out {
      margin-bottom: 0;
      display: flex;
      justify-content: center;
      position: relative;

      .btn-help {
        padding: 0.18rem 0;
        border: 1px solid $orange;
        border-top-left-radius: $rounded-btn-border-radius;
        border-bottom-left-radius: $rounded-btn-border-radius;
        border-top-right-radius: $rounded-btn-border-radius;
        border-bottom-right-radius: $rounded-btn-border-radius;
        background-color: $orange;

        font-family: "FuturaPT-Medium", sans-serif;
        font-size: getFontSizeRem(20);
        width: 2.2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $blue-deep;
      }
    }

    .btn-sign-out-wrapper {
      position: absolute;
      top: 0;
      right: 0;
      bottom: inherit;
      left: inherit;
    }

    .timer-score-wrapper {
      display: flex;

      .timer-block, .score-block {
        flex: 1;

        p {
          font-size: getFontSizeRem(32);
        }

        span {
          font-family: FuturaPT-Light, sans-serif;
        }

        .countdown {
          font-family: "FuturaPT-Medium", sans-serif;
        }
      }

      .timer-block {
        margin-right: 1rem;
      }
    }

    .players-list {
      width: 100%;
      margin: 0;
      display: flex;

      .col {
        flex: 1;
        font-family: "FuturaPT-Light", sans-serif;
        font-size: 0.5rem;
        line-height: 0.65rem;

        .name-cell {
          &::first-letter {
            text-transform: uppercase;
          }
        }
      }

      .current-user {
        font-family: "FuturaPT-Medium", sans-serif;
      }
    }

    .clues-container {
      padding-top: 0.35rem;
    }

    .game-hud-clue {
      display: flex;
      align-items: center;
      margin-bottom: 0.5rem;

      button {
        min-width: 70px;
        width: 36%;
        position: relative;
        color: $orange;
        border: 1px solid $orange;
        border-top-left-radius: getFontSizeRem(30) 50%;
        border-bottom-left-radius: getFontSizeRem(30) 50%;
        border-top-right-radius: getFontSizeRem(30) 50%;
        border-bottom-right-radius: getFontSizeRem(30) 50%;
        padding: 0.2rem;
        background: transparent;
        line-height: getFontSizeRem(16);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        span {
          display: block;
        }

        .clue-name {
          font-family: "FuturaPT-Medium", sans-serif;
          display: block;
          font-size: getFontSizeRem(18);
          margin-left: -0.25rem;
        }

        .clue-value {
          font-family: "FuturaPT-Medium-Italic", sans-serif;
          font-size: getFontSizeRem(14);
          color: #9D9D9D;
          margin-left: -0.25rem;
          margin-top: 2px;
        }

        img {
          width: 10%;
          position: absolute;
          right: 9%;
          top: 48%;
          transform: translateY(-50%);
          opacity: 1;
        }

        .padlock-open {
          width: 12%;
        }
      }

      .locked {
        border: 1px solid $orange-mate;
      }

      .opened {
        color: $blue-deep;
        border: 1px solid $blue-deep;
        background: $orange;

        .clue-value {
          color: #504f4f;
        }
      }

      .clue-msg {
        flex: 1;
        color: $orange;
        font-family: "FuturaPT-Book-Italic", sans-serif;
        font-size: getFontSizeRem(22);
        margin-left: 0.25rem;
      }
    }

    .game-hud-clue-disabled {
      opacity: 0.3;
    }
  }
}
