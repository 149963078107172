@mixin username-definition {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;

  background-image: getURL('assets/images/dark-building-wireframe.svg');
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;

  .logo-container {
    flex-basis: 20%;
    text-align: center;
    margin-bottom: 1rem;

    img {
      width: auto;
      height: 100%;
      max-height: 120px;
    }
  }

  .welcome-wrapper {
    flex: 1;
    text-align: center;
  }

  h1 {
    font-size: getFontSizeRem(46);
    margin-bottom: 0.5rem;

    span {
      text-transform: uppercase;
      font-family: "FuturaPT-Bold", sans-serif;
    }
  }

  .username-form {
    display: flex;
    align-items: center;
    flex-direction: column;

    .input-form-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;

      .message {
        font-size: getFontSizeRem(16);
      }
    }

    p {
      font-size: getFontSizeRem(35);
      margin-bottom: 1rem;
    }
  }
}